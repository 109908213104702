import React from "react";
import PropTypes from 'prop-types'
const Container = ({ children, className }) => {
  return <div className={`container pt-1 sm:pt-8 mx-auto ${className}`}>{children}</div>;
};

Container.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

Container.defaultProps = {
  className: ''
}

export default Container;
