import React from "react";
import Container from "../components/common/Container";

const Footer = () => {
  return (
    <div className="pb-6 bg-footer-black">
      <Container>
        <footer className="px-5 py-3">
          <div className="flex flex-wrap items-center justify-center sm:justify-between">
            <img
              className="w-full mb-4 sm:mb-0 sm:w-1/5"
              src={require("../images/whitelogo.png")}
              alt=""
            />
            <div className="mb-4 text-center text-white sm:mb-0">
              <p>support@unboggled.com</p>
              {/* <p>7091 4TH ST STE 300</p>
              <p>ST. PETERSBURG, FL 33702</p> */}
            </div>
            <div>
              <img src={require("../images/foasdv.png")} alt="" />
            </div>
          </div>
        </footer>
      </Container>
    </div>
  );
};

export default Footer;
