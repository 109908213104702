import React from "react";
import PropTypes from "prop-types";

const Overlay = ({ children }) => {
  return (
    <div>
      {children ? (
        <div className="fixed inset-0 w-screen h-screen bg-overlay">
          {children}
        </div>
      ) : (
        <div className="fixed inset-0 w-screen h-screen bg-overlay"></div>
      )}
    </div>
  );
};

Overlay.propTypes = {
  children: PropTypes.node,
};

Overlay.defaultProps = {
  children: null,
};

export default Overlay;
