import React from "react";
import PropTypes from 'prop-types'
import DesktopNavigation from "./DesktopNavigation";
import MobileNavigation from "./MobileNavigation";
import Container from "../components/common/Container";
import Logo from '../components/misc/Logo'

const Navbar = ({routes}) => {    
  return (
    <Container className="relative z-50 pr-4 sm:pl-2 lg:px-6">
      <div className="flex items-center justify-between">
        <Logo className="w-48 xl:w-56" />
        <DesktopNavigation links={routes} />
        <MobileNavigation links={routes} />
      </div>
    </Container>
  );
};

Navbar.propTypes = {
  routes: PropTypes.array.isRequired,
}

export default Navbar;