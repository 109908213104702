import React from "react";
import PropTypes from "prop-types";
import Navbar from "./Navbar";
import Footer from "./Footer";

const AppLayout = ({ children, routes }) => {
  return (
    <div className="relative">
      <Navbar routes={routes} />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  routes: PropTypes.array.isRequired,
};

export default AppLayout;
