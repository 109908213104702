import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

// import Logo from "../components/misc/Logo";

const DesktopNavigation = ({links}) => {
  return (
    <nav className="relative z-40 hidden lg:block">
      <ul className="flex lg:space-x-10 xl:space-x-16">
        {links.map((link) => (
          <li key={link.href}>
            <Link to={link.href} className="font-bold hover:text-green-400">
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

DesktopNavigation.propTypes = {
  links: PropTypes.array.isRequired,
};

export default DesktopNavigation;
