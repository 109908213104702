import React, { useState } from "react";
import PropTypes from "prop-types";
import Hamburger from "../components/misc/Hamburger";
import NavDrawer from "./NavDrawer";

const MobileNavigation = ({ links }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <nav className="block lg:hidden">
      <NavDrawer links={links} open={open} toggle={toggle} />
      <button
        onClick={toggle}
        className="p-2 duration-100 rounded-full focus:text-green-500 focus:bg-faded-gray focus:outline-none"
      >
        <Hamburger className="w-6 h-6" />
      </button>
    </nav>
  );
};

MobileNavigation.propTypes = {
  links: PropTypes.array.isRequired,
};

export default MobileNavigation;
