import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useTransition, animated } from "react-spring";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Overlay from "../components/misc/Overlay";
import Logo from "../components/misc/Logo";

const NavDrawer = ({ open, toggle, links }) => {
  const fadeTransition = {
    from: { opacity: 0, position: "absolute" },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  };
  const slideTransition = {
    from: { transform: "translateX(-500px)" },
    enter: { transform: "translateX(0px)" },
    leave: { transform: "translateX(-500px)" },
  };
  const fade = useTransition(open, null, {
    ...fadeTransition,
  });
  const slide = useTransition(open, null, {
    ...slideTransition,
  });

  return (
    <div>
      {fade.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <Overlay />
            </animated.div>
          )
      )}
      <OutsideClickHandler
        onOutsideClick={() => {
          if (open) {
            toggle()
          }
        }}
      >
        {slide.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                className="fixed inset-0 z-40 w-3/4 h-screen bg-white shadow-2xl sm:w-1/3"
                key={key}
                style={props}
              >
                <div className="px-4">
                  <div className="flex items-center justify-between">
                    <Logo className="w-32" />
                    <button
                      onClick={toggle}
                      className="text-3xl focus:outline-none focus:text-green-500"
                    >
                      &times;
                    </button>
                  </div>
                  <ul>
                    {links.map((link) => (
                      <li
                        className="mt-5 font-bold hover:text-green-500"
                        key={link.href}
                      >
                        <Link to={link.href}>{link.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </animated.div>
            )
        )}
      </OutsideClickHandler>
    </div>
  );
};

NavDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
};

export default NavDrawer;
